import { lazy } from 'react';
import { DocumentationRoute, IDocItem } from '@/@types/docs';
import { Button } from '@/components/ui';
import { downloadJSON } from '@/utils/DownloadJson';
import PolicyJSON from '@/assets/AWS/Policy.json';
import { Link } from 'react-router-dom';

const imagePath =
    import.meta.env.VITE_VAMU_SPACE_OBJECT_STORAGE_BASE_URL + '/images/docs';

export const documentationRoutes: DocumentationRoute[] = [
    {
        groupName: 'Getting Started',
        nav: [
            {
                path: 'aws',
                label: 'Aws Integration',
                component: lazy(
                    () =>
                        import(
                            '@/views/Documentation/components/Integrations/AwsIntegrationDoc'
                        ),
                ),
            },
            {
                path: 'slack',
                label: 'Slack Notification',
                component: lazy(
                    () =>
                        import(
                            '@/views/Documentation/components/Integrations/SlackIntegrationDoc'
                        ),
                ),
            },
        ],
    },
];

export const AwsIntegrationDocList: IDocItem[] = [
    {
        key: 'step-1',
        desc: 'Log in to your AWS account.',
    },
    {
        key: 'step-2',
        desc: (
            <span>
                Search for<strong> Security credentials </strong>in the AWS
                Management Console, then click on{' '}
                <strong>Users (IAM Features)</strong> Select{' '}
                <strong>Create User</strong>
            </span>
        ),
        images: [
            `${imagePath}/aws/docs-0.png`,
            `${imagePath}/aws/docs-1.png`,
            `${imagePath}/aws/docs-2.png`,
            `${imagePath}/aws/docs-3.png`,
            `${imagePath}/aws/docs-4.png`,
        ],
    },
    {
        key: 'step-3',
        desc: (
            <span>
                While setting permissions for the user, options for{' '}
                <strong> Attach policies directly </strong>and include the
                following policies:
            </span>
        ),

        children: [
            {
                key: 'step-3.0',
                desc: (
                    <span>
                        <Button
                            size="sm"
                            variant="twoTone"
                            onClick={() => downloadJSON(PolicyJSON)}
                        >
                            Downlod JSON file
                        </Button>{' '}
                        save it will be used later
                    </span>
                ),
            },
            {
                key: 'step-3.1',
                desc: (
                    <span>
                        Click on <strong>Create policy</strong> - this will open
                        a new tab - and choose JSON. Paste the attached JSON
                        content
                    </span>
                ),
                images: [
                    `${imagePath}/aws/docs-5.png`,
                    `${imagePath}/aws/docs-7.png`,
                ],
            },
            {
                key: 'step-3.2',
                desc: 'Fill in the policy name, and click create policy at the bottom.',
            },
            {
                key: 'step-3.3',
                desc: (
                    <span>
                        Once created. Go back to the first tab and search for
                        the policy name you just created. If you {"can't"}{' '}
                        locate the custom policy, click the refresh icon next to
                        the <strong>Create policy</strong> button.
                    </span>
                ),
                images: [`${imagePath}/aws/docs-12.png`],
            },
            {
                key: 'step-3.4',
                desc: 'Choose the policy, and then choose the following two policies to attach:',
                images: [`${imagePath}/aws/docs-13.png`],

                children: [
                    {
                        key: 'step-3.4.1',
                        desc: <b>SecurityAudit</b>,
                        images: [`${imagePath}/aws/docs-14.png`],
                    },
                    {
                        key: 'step-3.4.2',
                        desc: <b>ViewOnlyAccess</b>,
                        images: [`${imagePath}/aws/docs-15.png`],
                    },
                ],
            },
            {
                key: 'step-3.5',
                desc: (
                    <span>
                        Once all three policies are chosen, click next, you will
                        see the three policies in the{' '}
                        <strong>permission summary</strong>
                    </span>
                ),
            },
            {
                key: 'step-3.6',
                desc: 'Click Create user',
                images: [`${imagePath}/aws/docs-17.png`],
            },
        ],
    },
    {
        key: 'step-4',
        desc: 'Click on the user you created.',
    },
    {
        key: 'step-5',
        desc: (
            <span>
                Click on the <strong>Security credentials</strong> tab
            </span>
        ),
        images: [`${imagePath}/aws/docs-19.png`],
    },
    {
        key: 'step-6',
        desc: (
            <span>
                Scroll down to <strong>Access Keys</strong> and click on{' '}
                <strong>Create access key.</strong>
            </span>
        ),
        images: [`${imagePath}/aws/docs-20.png`],
    },
    {
        key: 'step-7',
        desc: (
            <span>
                Choose <strong>Command Line Interface (CLI)</strong>, accept the
                confirmation button at the bottom, and proceed to the next step.
            </span>
        ),
        images: [
            `${imagePath}/aws/docs-21.png`,
            `${imagePath}/aws/docs-22.png`,
        ],
    },
    {
        key: 'step-8',
        desc: (
            <span>
                Provide a description for the access key and click{' '}
                <strong>Create Access Key.</strong>
            </span>
        ),
        images: [
            `${imagePath}/aws/docs-24.png`,
            `${imagePath}/aws/docs-25.png`,
        ],
    },
    {
        key: 'step-9',
        desc: (
            <span>
                Save both the <strong>Access key</strong> and{' '}
                <strong>Secret access key</strong> These two are essential for
                connecting your AWS cloud to Vamu. You will have to provide
                those two at the Vamu SaaS interface
            </span>
        ),
        images: [`${imagePath}/aws/docs-26.png`],
    },
];

export const SlackDocList: IDocItem[] = [
    {
        key: 'step-1',
        desc: 'Login to your slack account.',
    },
    {
        key: 'step-2',
        desc: (
            <span>
                Go to the{' '}
                <strong>
                    <Link
                        to={'https://api.slack.com/apps'}
                        className="text-indigo-500"
                        target="_blank"
                    >
                        Slack API dashboard.
                    </Link>
                </strong>
            </span>
        ),
    },
    {
        key: 'step-3',
        desc: (
            <span>
                Click on <strong>Create New App</strong>
            </span>
        ),
        images: [`${imagePath}/slack/step-3.png`],
    },
    {
        key: 'step-4',
        desc: (
            <span>
                Choose <strong>(From Scratch)</strong> option.
            </span>
        ),
        images: [`${imagePath}/slack/step-4.png`],
    },
    {
        key: 'step-5',
        desc: (
            <span>
                Write the <strong>App Name</strong> and select your{' '}
                <strong>Workspace</strong> then click on{' '}
                <strong>Create App</strong>.
            </span>
        ),
        images: [`${imagePath}/slack/step-5.png`],
    },
    {
        key: 'step-6',
        desc: (
            <span>
                Navigate to the <strong>OAuth & Permissions</strong> section
            </span>
        ),
        children: [
            {
                key: 'step-6.0',
                images: [`${imagePath}/slack/step-6.png`],
            },
            {
                key: 'step-6.1',
                desc: (
                    <span>
                        Add the necessary <strong>Scope</strong>.
                    </span>
                ),
                images: [`${imagePath}/slack/step-6-1.png`],
            },
            {
                key: 'step-6.2',
                desc: (
                    <span>
                        Install the app in your workspace (Install to{' '}
                        <strong>Workspace Name</strong>).
                    </span>
                ),
                images: [`${imagePath}/slack/step-6-2.png`],
            },
            {
                key: 'step-6-3',
                desc: (
                    <span>
                        After installation you will be directed to page to allow
                        the permission. <strong>Click Allow</strong>
                    </span>
                ),
                images: [`${imagePath}/slack/step-6-3.png`],
            },
            {
                key: 'step-6-4',
                desc: (
                    <span>
                        Now <strong>Copy</strong> your{' '}
                        <strong>OAuth Token</strong> and save it any where as
                        you will use it later to enable the slack notification
                        under Vamu
                    </span>
                ),
                images: [`${imagePath}/slack/step-6-4.png`],
            },
        ],
    },
    {
        key: 'step-7',
        desc: (
            <span>
                Now go to your <strong>workspace</strong> create your new{' '}
                <strong>channel</strong> that you want to receive the messages
                from Vamu on it
            </span>
        ),
        children: [
            {
                key: 'step-7-1',
                desc: (
                    <span>
                        In channel, Click on the <strong>Members.</strong>
                    </span>
                ),
                images: [`${imagePath}/slack/step-7-1.png`],
            },
            {
                key: 'step-7-2',
                desc: (
                    <span>
                        In <strong>About </strong>
                        copy the <strong>Channel ID</strong> and save it any
                        where as you will use it later to enable the slack
                        notification under Vamu
                    </span>
                ),
                images: [`${imagePath}/slack/step-7-2.png`],
            },
            {
                key: 'step-7-3',
                desc: (
                    <span>
                        Then navigate to the <strong>integrations</strong> tap
                        and click (Add an app).
                    </span>
                ),
                images: [`${imagePath}/slack/step-7-3.png`],
            },
            {
                key: 'step-7-4',
                desc: (
                    <span>
                        Find the app that you recently created then click add.
                    </span>
                ),
            },
        ],
    },
    {
        key: 'step-8',
        desc: (
            <span>
                Now, return to the Vamu app, proceed with automation, and add
                the <strong>OAuth Token</strong> and <strong>Channel ID</strong>{' '}
                to initiate the connection.
            </span>
        ),
    },
];
