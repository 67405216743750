import ApiService from './ApiService';
import { InviteModalProps } from '@/views/Personnel/Team/components/InviteMemberModal';
import {
    DepartmentProps,
    DepartmentUserProps,
    ICompanyProps,
    IUpdateCompanyProps,
    UpdateDepartmentProps,
    UpdateDepartmentUserProps,
    UserProps,
} from '@/@types/auth';
import { ApiParamsType, IResponse } from '@/@types/common';

export async function apiGetAccountSettingData() {
    return await ApiService.fetchData<UserProps>({
        url: `/company/users/information/`,
        method: 'get',
    }).then((res) => res.data);
}

export const apiUpdateCompany = async (data: Partial<IUpdateCompanyProps>) => {
    return await ApiService.fetchData<IUpdateCompanyProps>({
        url: '/company/',
        method: 'PATCH',
        data,
    });
};

export async function fetchTeam<T extends ApiParamsType>(params?: T) {
    type ResponseType = T extends { all: true }
        ? UserProps[]
        : IResponse<UserProps[]>;
    return ApiService.fetchData<ResponseType>({
        url: '/company/users/',
        params,
    }).then((res) => res.data);
}
export const toggleMemberStatus = async (id: number) => {
    return ApiService.fetchData({
        url: `/company/users/${id}/status/`,
        method: 'PATCH',
    });
};
export const deleteUser = async (id: number) => {
    return ApiService.fetchData({
        url: `/company/users/${id}/`,
        method: 'DELETE',
    });
};
export const ChangeRole = async (id: number, role: string) => {
    return ApiService.fetchData({
        url: `/company/users/${id}/change-role/`,
        method: 'PATCH',
        data: {
            role: role,
        },
    });
};

export const resendInvitation = async (id: number) => {
    return ApiService.fetchData({
        url: `/company/users/${id}/resend-invitation/`,
        method: 'POST',
    });
};

export const fetchCompanySettings = async () => {
    return ApiService.fetchData<ICompanyProps>({
        url: '/company/',
    }).then((res) => res.data);
};

export const inviteUser = async (data: InviteModalProps) => {
    return ApiService.fetchData({
        url: '/company/users/',
        method: 'POST',
        data,
    }).then((res) => res.data);
};

export async function apiChangePassword(id: number) {
    return ApiService.fetchData({
        url: `/company/users/${id}/change-password/`,
        method: 'PUT',
    });
}

export async function apiGetDepartments<T extends ApiParamsType | undefined>(
    params?: T,
) {
    type ResponseType = T extends { all: true }
        ? DepartmentProps[]
        : IResponse<DepartmentProps[]>;

    return ApiService.fetchData<ResponseType>({
        url: `/departments/`,
        params,
    }).then((res) => res.data);
}

export const apiGetDepartmentById = async (departmentId: number) => {
    return ApiService.fetchData<DepartmentProps>({
        url: `/departments/${departmentId}/`,
    }).then((res) => res.data);
};

export const apiCreateDepartment = async (data: UpdateDepartmentProps) => {
    return ApiService.fetchData({
        url: '/departments/',
        method: 'POST',
        data,
    }).then((res) => res.data);
};

export const apiUpdateDepartment = async (
    id: number,
    data: UpdateDepartmentProps,
) => {
    return ApiService.fetchData({
        url: `/departments/${id}/`,
        method: 'PUT',
        data,
    }).then((res) => res.data);
};

export const apiDeleteDepartment = async (departmentId: number) => {
    return ApiService.fetchData({
        url: `/departments/${departmentId}/`,
        method: 'DELETE',
    });
};

export const apiAssignUserDepartment = async (
    departmentId: number,
    data: UpdateDepartmentUserProps,
) => {
    return ApiService.fetchData({
        url: `/departments/${departmentId}/users/`,
        method: 'POST',
        data,
    }).then((res) => res.data);
};

export const apiUpdateUserDepartmentPermissions = async (
    departmentId: number,
    userDepartmentId: number,
    data: {
        permissions: UpdateDepartmentUserProps['permissions'];
    },
) => {
    return ApiService.fetchData({
        url: `/departments/${departmentId}/users/${userDepartmentId}/`,
        method: 'PATCH',
        data,
    }).then((res) => res.data);
};

export const apiDeleteDepartmentUser = async (
    departmentId: number,
    userDepartmentId: number,
) => {
    return ApiService.fetchData({
        url: `/departments/${departmentId}/users/${userDepartmentId}/`,
        method: 'DELETE',
    });
};

export const apiGetUsersByDepartmentId = async <
    T extends ApiParamsType | undefined,
>(
    departmentIds: number[],
    params?: T,
): Promise<
    T extends { all: true }
        ? DepartmentUserProps[]
        : IResponse<DepartmentUserProps[]>
> => {
    type ResponseType = T extends { all: true }
        ? DepartmentUserProps[]
        : IResponse<DepartmentUserProps[]>;

    return ApiService.fetchData<ResponseType>({
        url: `/departments/users/`,
        params: {
            ...params,
            departments: departmentIds.join(','),
        },
    }).then((res) => res.data);
};
