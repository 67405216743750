export const ADMIN_ROLE = {
    label: 'admin',
    value: import.meta.env.VITE_AUTH0_ADMIN_ROLE,
};
export const MEMBER_ROLE = {
    label: 'member',
    value: import.meta.env.VITE_AUTH0_MEMBER_ROLE,
};

export const PermissionList = {
    ViewOnly: 'view',
    Change: 'change',
    Delete: 'delete',
} as const;

export const PermissionSet = [
    PermissionList.ViewOnly,
    PermissionList.Change,
    PermissionList.Delete,
] as const; // Must be asserted as const
