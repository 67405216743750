export function downloadJSON(JsonFile: Record<string, unknown>) {
    const dataStr =
        'data:application/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(JsonFile));
    const download = document.createElement('a');
    download.setAttribute('href', dataStr);
    download.setAttribute('download', 'Vamu-AWS-Policy' + '.json');
    document.body.appendChild(download);
    download.click();
    download.remove();
}
