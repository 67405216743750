import { Routes } from '@/@types/routes';
import { lazy } from 'react';

const complianceRoutes: Routes = [
    {
        key: 'compliance.frameworks',
        path: `/compliance/frameworks`,
        component: lazy(() => import('@/views/Compliance/Frameworks')),
        authority: [],
    },

    {
        key: 'compliance.frameworks',
        path: `/compliance/frameworks/:id/controls`,
        component: lazy(
            () => import('@/views/Compliance/Controls/FrameworkControls'),
        ),
        authority: [],
    },

    {
        key: 'compliance.controls',
        path: `/compliance/controls`,
        resource: 'controls',
        component: lazy(() => import('@/views/Compliance/Controls')),
        authority: [],
    },

    {
        key: 'compliance.tests',
        path: `/compliance/tests`,
        resource: 'tests',
        component: lazy(
            () => import('@/views/Compliance/ComplianceTests/index'),
        ),
        authority: [],
    },
    {
        key: 'compliance.tests',
        path: `/compliance/tests/:testId`,
        resource: 'tests',
        component: lazy(
            () => import('@/views/Compliance/ComplianceTests/TestDetails'),
        ),
        authority: [],
    },
    {
        key: 'compliance.policies',
        path: `/compliance/policies`,
        resource: 'policies',
        component: lazy(
            () => import('@/views/Compliance/Documents&Policies/Policies'),
        ),
        authority: [],
    },
    {
        key: 'compliance.policies',
        path: `/compliance/policies/:id`,
        resource: 'policies',
        component: lazy(
            () =>
                import(
                    '@/views/Compliance/Documents&Policies/PolicyOrDocumentDetailPage'
                ),
        ),
        authority: [],
    },
    {
        key: 'compliance.documents',
        path: `/compliance/documents`,
        resource: 'documents',

        component: lazy(
            () => import('@/views/Compliance/Documents&Policies/Documents'),
        ),
        authority: [],
    },
    {
        key: 'compliance.documents',
        path: `/compliance/documents/:id`,
        resource: 'documents',

        component: lazy(
            () =>
                import(
                    '@/views/Compliance/Documents&Policies/PolicyOrDocumentDetailPage'
                ),
        ),
        authority: [],
    },
];
export default complianceRoutes;
