import { deferredGetAccessToken, deferredLogout } from '@/utils/auth0Utils';
import useQuery from '@/utils/hooks/useQuery';
import { AppState, Auth0Context, Auth0Provider } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

const Auth0Wrapper = ({ children }: PropsWithChildren) => {
    const searchParams = useQuery();
    const navigate = useNavigate();

    const invitation = searchParams.get('invitation') ?? undefined;
    const organization = searchParams.get('organization') ?? undefined;

    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo ?? window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={import.meta.env.VITE_AUTH0_DOMAIN}
            clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            authorizationParams={{
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                organization: organization,
                invitation: invitation,
                prompt: 'login',
            }}
            onRedirectCallback={onRedirectCallback}
        >
            <Auth0Context.Consumer>
                {({ getAccessTokenSilently, logout }) => {
                    deferredGetAccessToken.resolve(getAccessTokenSilently);
                    deferredLogout.resolve(logout);
                    return children;
                }}
            </Auth0Context.Consumer>
        </Auth0Provider>
    );
};
export default Auth0Wrapper;
