import BaseService from './BaseService';

import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Notify from '@/utils/Notify';
import { CustomErrorResponseProps } from '@/@types/common';

const ApiService = {
    fetchData<Response = unknown, Request = Record<string, unknown>>(
        param: AxiosRequestConfig<Request>,
    ) {
        return new Promise<AxiosResponse<Response>>((resolve, reject) => {
            BaseService(param)
                .then((response: AxiosResponse<Response>) => {
                    resolve(response);
                })
                .catch((errors: AxiosError<CustomErrorResponseProps>) => {
                    const statusCode = errors?.response?.status;
                    if (statusCode === 500) {
                        Notify({
                            type: 'warning',
                            title: 'Something went wrong, Contact us for support',
                        });
                    }

                    reject(errors);
                });
        });
    },
};

export default ApiService;
