import { Routes } from '@/@types/routes';
import { lazy } from 'react';

const settingsRoutes: Routes = [
    {
        key: 'personnel.organization',
        path: `/personnel/organization`,
        component: lazy(() => import('@/views/Personnel/Organization')),
        authority: [],
    },
    {
        key: 'personnel.departments',
        path: `/personnel/departments`,
        component: lazy(() => import('@/views/Personnel/Departments')),
        authority: [],
    },
    {
        key: 'personnel.team',
        path: `/personnel/team`,
        component: lazy(() => import('@/views/Personnel/Team')),
        authority: [],
    },
    {
        key: 'personnel.profile',
        path: `/personnel/profile`,
        component: lazy(() => import('@/views/Personnel/Profile')),
        authority: [],
    },
];
export default settingsRoutes;
