import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_BASE_NAME } from './constants';
import { ResourceKey } from '@/@types/auth';

export type CommonState = {
    currentRouteKey: string;
    currentRouteResource?: ResourceKey;
};

export const initialState: CommonState = {
    currentRouteKey: '',
    currentRouteResource: undefined,
};

export const commonSlice = createSlice({
    name: `${SLICE_BASE_NAME}/common`,
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action: PayloadAction<string>) => {
            state.currentRouteKey = action.payload;
        },
        setCurrentRouteResource: (
            state,
            action: PayloadAction<ResourceKey>,
        ) => {
            state.currentRouteResource = action.payload;
        },
    },
});

export const { setCurrentRouteKey, setCurrentRouteResource } =
    commonSlice.actions;

export default commonSlice.reducer;
