import { createContext, useContext } from 'react';

export type TabsVariant = 'underline' | 'pill';

type TabsContextProps = {
    onValueChange?: (tabValue: string) => void;
    value?: string;
    variant?: TabsVariant;
};

const TabsContext = createContext<TabsContextProps>({});

export const TabsContextProvider = TabsContext.Provider;

export const TabsContextConsumer = TabsContext.Consumer;

export function useTabs() {
    return useContext(TabsContext);
}

export default TabsContext;
