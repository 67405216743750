import { lazy } from 'react';
import type { Routes } from '@/@types/routes';

const authRoute: Routes = [
    {
        key: 'signIn',
        path: `/sign-in`,
        component: lazy(() => import('@/views/Auth/SignIn')),
    },
    {
        key: 'error',
        path: `/error`,
        component: lazy(() => import('@/views/Common/AccessDenied')),
    },
];

export default authRoute;
