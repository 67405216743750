import {
    Alert,
    AlertProps,
    Notification,
    NotificationProps,
    toast,
} from '@/components/ui';
import { ReactNode } from 'react';

interface NotifyProps {
    type?: 'success' | 'warning' | 'danger' | 'info';
    title?: string;
    message?: ReactNode;
    alert?: boolean;
    props?: true extends this['alert'] ? AlertProps : NotificationProps;
}

const Notify = (props: NotifyProps) => {
    const { message, alert, type = 'success', ...rest } = props;
    const Component = alert ? Alert : Notification;

    toast.push(
        <Component type={type} closable duration={10000} {...rest}>
            {message ?? ''}
        </Component>,
        alert
            ? {
                  offsetX: 0,

                  offsetY: 0,

                  transitionType: 'fade',

                  block: true,
                  placement: 'top-end',
              }
            : {},
    );
};

export default Notify;
