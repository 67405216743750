import { UserProps } from '@/@types/auth';
import { ADMIN_ROLE } from '@/constants';
import { apiGetAccountSettingData } from '@/services/AccountService';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const useUserInfo = () => {
    const queryClient = useQueryClient();
    const { user } = useAuth0();
    const userId = user?.sub ?? '';
    const cachedUser = queryClient.getQueryData(['user', userId]) as UserProps;

    const { data } = useQuery({
        queryKey: ['user', userId],
        queryFn: () => apiGetAccountSettingData(),
        enabled: !!userId && !cachedUser,
        initialData: cachedUser,
    });
    const isAdmin = data?.role.value === ADMIN_ROLE.value;

    return { ...data, isAdmin };
};
export default useUserInfo;
