import { useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import { nanoid } from 'nanoid';

export default function useUniqueId(prefix = '', len = 10) {
    const idRef = useRef<string>();

    if (!idRef.current) {
        idRef.current = `${uniqueId(prefix)}-${nanoid(len)}`;
    }

    return idRef.current;
}
