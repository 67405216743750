import { Routes } from '@/@types/routes';
import { lazy } from 'react';

const miscsRoutes: Routes = [
    {
        key: 'overview.home',
        path: `/home`,
        component: lazy(() => import('@/views/Compliance/Home')),
        authority: [],
    },
    {
        key: 'access-denied',
        path: `/access-denied`,
        component: lazy(() => import('@/views/Common/AccessDenied')),
        authority: [],
    },

    {
        key: 'support.documentation',
        path: `/documentation/*`,
        component: lazy(() => import('@/views/Documentation')),
        authority: [],
    },

    {
        key: 'support.feedback',
        path: `/support/feedback`,
        component: lazy(() => import('@/views/Feedback')),
        authority: [],
    },
    {
        key: 'coming-soon',
        path: `/coming-soon`,
        component: lazy(() => import('@/views/Common/ComingSoon')),
        authority: [],
    },
    {
        key: 'not-found',
        path: `/not-found`,
        component: lazy(() => import('@/views/Common/NotFound')),
        authority: [],
    },
];
export default miscsRoutes;
