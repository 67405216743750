import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { CustomOption } from '@/@types/common';
import {
    IResourcesPermissions,
    IPermissionSet,
    ResourceKey,
} from '@/@types/auth';
import { useAppSelector } from '@/store';
import useUserInfo from './useUserInfo';
import { ADMIN_ROLE } from '@/constants';

type AuthorityProps = {
    userRole?: CustomOption;
    userPermissions?: IResourcesPermissions;
    requiredResource?: ResourceKey;
    fallback?: IPermissionSet;
};

function useAuthority(props?: AuthorityProps): IPermissionSet {
    const currentResourceKey = useAppSelector(
        (state) => state.base.common.currentRouteResource,
    );
    const { role, permissions } = useUserInfo();

    const _fallback: IPermissionSet = useMemo(
        () =>
            props?.fallback || {
                change: false,
                view: false,
                delete: false,
            },
        [props?.fallback],
    );

    const _userRole = props?.userRole || role;
    const _userPermissions = props?.userPermissions || permissions;
    const _requiredResource: ResourceKey =
        props?.requiredResource ?? currentResourceKey;

    const currentPermissions = useMemo(() => {
        if (!_userRole || isEmpty(_userPermissions)) return _fallback;

        // Admin role has full permissions
        if (_userRole.value === ADMIN_ROLE.value || !_requiredResource) {
            return { change: true, delete: true, view: true };
        }

        const modulePermissions = _userPermissions[_requiredResource];
        return modulePermissions || _fallback;
    }, [_userRole, _userPermissions, _requiredResource, _fallback]);

    return currentPermissions;
}

export default useAuthority;
