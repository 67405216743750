import {
    configureStore,
    Action,
    Reducer,
    UnknownAction,
    Store,
} from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    createMigrate,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PERSIST_STORE_NAME } from '@/constants';
import rootReducer, { RootState, AsyncReducers } from './rootReducer';
import RtkQueryService from '@/services/RtkQueryService';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const migrations = {
    1: (state: RootState) => {
        return { ...state };
    },
    2: (state: RootState) => {
        return {
            ...state,
            base: state.base,
            locale: state.locale,
            theme: state.theme,
            auth: undefined,
        };
    },
};

const middlewares = [RtkQueryService?.middleware];

const persistConfig = {
    key: PERSIST_STORE_NAME,
    keyPrefix: '',
    storage,
    whitelist: ['auth', 'locale'],
    version: 2,
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations, { debug: false }),
};

interface CustomStore extends Store<RootState, UnknownAction> {
    asyncReducers?: AsyncReducers;
}

export const store: CustomStore = configureStore({
    reducer: persistReducer(persistConfig, rootReducer() as Reducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(middlewares),
    devTools: JSON.stringify(import.meta.env).includes('dev'),
});

store.asyncReducers = {};

export const persistor = persistStore(store);

export function injectReducer<S>(key: string, reducer: Reducer<S, Action>) {
    if (store.asyncReducers) {
        if (store.asyncReducers[key]) {
            return false;
        }
        store.asyncReducers[key] = reducer;
        store.replaceReducer(
            persistReducer(
                persistConfig,
                rootReducer(store.asyncReducers) as Reducer,
            ),
        );
    }
    persistor.persist();
    return store;
}

export type AppDispatch = typeof store.dispatch;

export default store;
