import { Routes } from '@/@types/routes';
import authRoute from './authRoute';
import automationRoutes from './automationRoutes';
import complianceRoutes from './complianceRoutes';
import personnelRoutes from './personnelRoutes';
import miscsRoutes from './miscsRoutes';
import { lazy } from 'react';

const riskRoutes: Routes = [
    {
        key: 'risk.overview',
        path: `/risk-management/overview`,
        component: lazy(() => import('@/views/RiskManagement/Overview')),
        authority: [],
    },
    {
        key: 'risk.register',
        resource: 'riskRegistry',
        path: `/risk-management/register`,
        component: lazy(() => import('@/views/RiskManagement/RiskRegister')),
        authority: [],
    },
    {
        key: 'risk.library',
        resource: 'riskLibrary',
        path: `/risk-management/library`,
        component: lazy(() => import('@/views/RiskManagement/RiskLibrary')),
        authority: [],
    },
];

const accessControlRoutes: Routes = [
    {
        resource: 'admin',
        key: 'access-control.people',
        path: `/access-control/people`,
        component: lazy(() => import('@/views/AccessControl/People')),
        authority: [],
    },
    {
        resource: 'admin',
        key: 'access-control.access',
        path: `/access-control/access`,
        component: lazy(() => import('@/views/AccessControl/Access')),
        authority: [],
    },
];

export const publicRoutes: Routes = [...authRoute];

export const protectedRoutes: Routes = [
    ...automationRoutes,
    ...complianceRoutes,
    ...personnelRoutes,
    ...miscsRoutes,
    ...riskRoutes,
    ...accessControlRoutes,
];
